@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: black;
}


/* #Header::before {  
  transform: scaleX(0);
  transform-origin: bottom right;
}

#Header:hover::before {
  transform: scaleX(1);
  transform-origin: bottom left;
}

#Header::before {
  content: " ";
  display: block;
  position: absolute;

  inset: 0 0 0 0;
  background: hsl(200 100% 80%);
  z-index: -1;
  transition: transform .3s ease;
} */

.button_slide {
  box-shadow: inset 0 0 0 0 #57e2e9;
  transition: ease-out 0.7s;
}

.slide_right:hover {
  box-shadow: inset 800px 0 0 0 #57e2e9;
  color: black;
  outline: black;
  z-index: 100;
}